'use client';
import React from 'react';
import {Field} from 'redux-form';
import Link from 'next/link';

import Login from '../Login/Login';
import TextInput from 'features/form/components/TextInput/TextInput';
import SubmitButton from 'features/form/components/SubmitButton/SubmitButton';

import {
    LoginTitle,
    LoginSubtext,
    LoginError,
    textInput,
    button,
    bottomLink,
} from './styles';
import {cleanUpEmail} from '../../../../utils/helpers';
import WarningIcon from 'assets/img/icons/error_outline.svg';

const LoginForm = (props) => {
    /**
     * "error" is namespaced by redux-form
     * customError is an error that persists from forced logout
     */
    const error = props.error || props.customError;

    return (
        <Login>
            {!props.hasLoginAuthCode && (
                <form onSubmit={props.handleSubmit(props.onSubmit)}>
                    <LoginTitle>Welcome Back!</LoginTitle>
                    <LoginSubtext>Please enter your login details.</LoginSubtext>
                    {error && (
                        <LoginError error={error}>
                            <div style={{width: '24px'}}>
                                <WarningIcon />
                            </div>
                            {error}
                        </LoginError>
                    )}
                    <Field
                        style={textInput}
                        normalize={cleanUpEmail}
                        component={TextInput}
                        name="username"
                        placeholder="Email"
                        label="Email"
                    />
                    <Field
                        style={textInput}
                        component={TextInput}
                        name="password"
                        type="password"
                        placeholder="Password"
                        label="Password"
                    />
                    <SubmitButton
                        {...props}
                        style={button}
                        submitText="Sign In"
                        submittingText="Signing in..."
                    />
                    <Link style={bottomLink} href="/forgot_password">
                        Forgot Password?
                    </Link>
                </form>
            )}
        </Login>
    );
};

export default LoginForm;

import {COLORS, newCOLORS} from 'core/styles';
import styled from 'styled-components';

export let LoginTitle = styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 8px;
`;

export const LoginSubtext = styled.div`
    color: #6a6a6a;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 32px;
`;

export let LoginError = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 8px;
    border-radius: 8px;
    border: 2px solid #e35141;
    background: #fff0ee;
    color: #e35141;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px;
    margin: 32px 0;
`;

export let error = (props) => {
    const error = props.error || props.customError;

    let styles = {
        display: 'block',
        position: 'relative',
        color: 'transparent',
        margin: 0,
        padding: 0,
        height: 0,
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '6px',
        opacity: 0,
        transition: 'all 150ms linear',
        textAlign: 'center',
    };

    if (error) {
        styles = {
            ...styles,
            padding: '5px',
            transition: 'opacity 150ms linear',
            height: 'auto',
            opacity: 1,
            color: '#fff',
            backgroundColor: COLORS.danger,
            marginBottom: '10px',
        };
    }

    return styles;
};

export let textInput = {
    maxWidth: '100%',
};

export let bottomLink = {
    display: 'block',
    cursor: 'pointer',
    color: newCOLORS.primary300,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
};

export let button = {
    marginTop: '8px',
    marginBottom: '16px',
    width: '100%',
    height: '48px',
    fontSize: '16px',
    fontWeight: '500',
};

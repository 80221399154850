import React, {useState, useRef, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {reduxForm, SubmissionError, formValueSelector} from 'redux-form';

import {selectCurrentUser} from 'features/users/selectors';
import {withRouter, get, isEmpty} from 'utils/helpers';
import {validate, validEmail as isInvalidEmail} from 'utils/validateForm';
import {loginUser} from 'features/auth/actions';
import LoginForm from 'features/auth/components/LoginForm/LoginForm';

import {useRouter} from 'next/navigation';

let LoginFormContainer = (props) => {
    const [error, setError] = useState(props.error);
    const hasLoginAuthCode = useRef(false);

    useEffect(() => {
        if (props.error) {
            setError(props.error);
        }
    }, [props.error]);

    const router = useRouter();
    const history = useSelector((state) => state.history);
    const previousUrl = history?.[1] || '';
    const conditions = ['login', 'logout', 'login2fa'];
    const isPreviousUrlValid = conditions.some((el) => !previousUrl.includes(el));

    const onSubmit = (values) => {
        const legacyLogin =
            values.username && values.username.length && isInvalidEmail(values.username);

        if (!values.username) {
            return Promise.reject(
                new SubmissionError({
                    _error: 'Your email is required',
                }),
            );
        }

        if (legacyLogin) {
            return Promise.reject(
                new SubmissionError({
                    _error: 'Invalid email address',
                }),
            );
        }

        if (!values.password) {
            return Promise.reject(
                new SubmissionError({
                    _error: 'Your password is required',
                }),
            );
        }

        const {redirectedFrom} = props;
        return props.loginUser(values).then((data) => {
            if (data?.data?.login_auth_code) {
                router.push('/login2fa');
            } else if (isPreviousUrlValid && previousUrl) {
                router.push(previousUrl);
            } else {
                router.push('/dashboard');
            }
        });
    };

    useEffect(() => {
        if (props.isAuthenticated && !isEmpty(props.user)) {
            router.push('/dashboard');
        }
    }, []);

    if (props.isAuthenticated && !isEmpty(props.user)) {
        return null;
    }

    return (
        <LoginForm
            {...props}
            customError={error ? error : props.error || null}
            hasLoginAuthCode={hasLoginAuthCode.current}
            onSubmit={onSubmit}
        />
    );
};

LoginFormContainer = reduxForm({
    form: 'login',
    validate,
})(LoginFormContainer);

LoginFormContainer = connect(
    (state, ownProps) => {
        return {
            user: selectCurrentUser(state),
            isAuthenticated: state.auth.isAuthenticated,
            login2FAUsername: ownProps.login2FAUsername,
            modal: state.modal,
            formValues: formValueSelector('login')(state, 'username', 'password'),
        };
    },
    {
        loginUser,
    },
)(LoginFormContainer);

export default withRouter(LoginFormContainer);
